import React from "react"
import area from "../../styles/icons/area.png"
import frame from "../../styles/icons/frame.png"
import bed from "../../styles/icons/bed.png"
import banera from "../../styles/icons/banera.png"
const QuickInfoPropiedad = (props) =>{
    return (
        <div className="description">
            {props.superficieConstruccion && (<div className="inline-div"><img className="iconPropiedad" src={area} alt="Ícono de superficie construida"/><span className="smallNotations">{props.superficieConstruccion} M<sup>2</sup></span></div>)}
            {props.superficieTerreno && (<div  className="inline-div"><img className="iconPropiedad" src={frame} alt="Ícono de superficie del terreno" /><span className="smallNotations">{props.superficieTerreno} M<sup>2</sup></span></div>)}
            {props.dormitorios? (<div  className="inline-div"><img className="iconPropiedad" src={bed} alt="Ícono para la cantidad de dormitorios"/><span className="smallNotations">{props.dormitorios}</span></div>): ""}
            {props.banos? (<div  className="inline-div"><img className="iconPropiedad" src={banera} alt="Ícono para la cantidad de baños" /><span className="smallNotations">{props.banos}</span></div>): ""}
        </div>
    )
}

export default QuickInfoPropiedad